export const state = () => ({
  form: {
    email: null,
    password: null,
    agreedToTerms: false,
  },
  rules: {
    email: {
      required: true,
      email: true,
      max: 80,
      regex: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
    },
    password: {
      required: true,
      min: 8,
      regex: /^[a-zA-Z0-9]*$/,
    },
    agreedToTerms: {
      required: true,
    },
  },
  customRules: {
    softbank: {
      softbank: '',
    },
    restrictedEmails: {
      restrictedEmails: '',
    },
  },
})

export const getters = {
  email: (state) => {
    return state.form.email
  },
}

export const mutations = {
  SET_FORM(state, form) {
    state.form.email = form.email
    state.form.password = form.password
    state.form.agreedToTerms = form.agreedToTerms
  },
}

export const actions = {
  async initForm({ commit, state }) {
    if (state.form === null) {
      const form = {
        email: '',
        password: '',
        agreedToTerms: false,
      }
      commit('SET_FORM', form)
    }
    return state.form
  },
  flushForm({ commit }) {
    commit('SET_FORM', { email: '', password: '', agreedToTerms: false })
  },
}
