<template>
  <div v-show="$store.state.common.loading" class="c-loading">
    <img src="~/assets/images/loading.gif" alt />
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState('common', ['loading']),
  },
}
</script>

<style lang="scss" scoped>
@import '~assets/scss/foundation/base.scss';

.c-loading {
  width: 100%;
  height: 100vh;
  background: #fff;
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  z-index: 9999;
  opacity: 0.9;
  justify-content: center;
  align-items: center;
  img {
    height: auto;
    width: auto;
  }
}
</style>
