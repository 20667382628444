export default ({ store }, inject) => {
  inject('notify', {
    show(message) {
      store.commit('notify/visible', message)
      setTimeout(() => {
        store.commit('notify/invisible')
      }, 2000)
    },
  })
}
