<template>
  <div class="p-500">
    <h2 class="p-500__title">50X ERROR</h2>
    <p class="p-500__item">{{ $t('page-cannot-displayed') }}</p>
  </div>
</template>

<script>
export default {
  layout: 'errorPage',
}
</script>

<style lang="scss" scoped>
@import '~assets/scss/foundation/base.scss';
.p-500 {
  background: url('~assets/images/photo.png') no-repeat;
  background-size: 300px;
  background-position: center 240px;
  padding: 100px 0 576px;
  @include desktop() {
    background-size: 347px;
    background-position: right 234px;
    padding: 227px 0 385px;
  }
  &__title {
    color: color-text(body1);
    font-size: 2.2rem;
    font-weight: normal;
    @include desktop() {
      font-size: 4.4rem;
    }
  }
  &__item {
    color: color-text(gray);
    margin-top: 1rem;
    margin-bottom: 2rem;
    font-size: 1.6rem;
    @include desktop() {
      width: 60%;
      font-size: 1.8rem;
      margin-bottom: 5.5rem;
    }
  }
}
</style>
