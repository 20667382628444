import Vue from 'vue'
import VeeValidate, { Validator } from 'vee-validate'

import ja from 'vee-validate/dist/locale/ja'

Validator.localize('ja', ja)
Vue.use(VeeValidate, { locale: ja })

//- softbankのメールアドレスを弾くカスタムルール
Validator.extend('softbank', {
  getMessage: () => {
    return '以下を含むメールアドレスはご使用いただけません。別のメールアドレスをご登録ください。<br>@softbank.ne.jp、@vodafone.jp'
  },
  validate: (value) => {
    const pattern = /.*@softbank.ne.jp|.*@vodafone.jp/gi
    return !value.match(pattern)
  },
})

//- 特定のメールアドレスを弾くカスタムルール
Validator.extend('restrictedEmails', {
  getMessage: () => {
    return '以下のドメインのアドレスはご使用いただけません。別のメールアドレスをご登録ください。<br>abuse@、database@、fbl@、ftp@、noc@、post@、postbox@、postmaster@、privacy@、remove@、root@、spam@、subscribe@、uucp@、webmaster@、welcome@、www@'
  },
  validate: (value) => {
    const pattern = /^(abuse|database|fbl|ftp|noc|post|postbox|postmaster|privacy|remove|root|spam|subscribe|uucp|webmaster|welcome|www)@.*/gi
    return !value.match(pattern)
  },
})
