<template>
  <div>
    <l-header />
    <div class="l-container">
      <nuxt />
    </div>
    <l-footer />
  </div>
</template>

<script>
import lFooter from '~/components/layouts/footer.vue'
import lHeader from '~/components/layouts/header.vue'

export default {
  components: {
    lFooter,
    lHeader,
  },
}
</script>

<style lang="scss" scoped>
@import '~assets/scss/foundation/base.scss';

.l-container {
  padding: 0 15px;
  margin: 0 auto;
  margin-top: calc(#{$headerHeightSP} + 24px);

  @include desktop() {
    padding: 0;
    max-width: 800px;
    margin-top: calc(#{$headerHeightPC} + 40px);
  }
}
</style>
