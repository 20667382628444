export const state = () => ({
  loading: false,
  lockLoading: false,
  apiStatus: null,
  apiMessage: '',
  redirectUrl: null,
  mobileApp: false,
})

export const getters = {
  isLoading: (state) => {
    return state.loading
  },
  apiStatus: (state) => {
    return {
      apiStatus: state.apiStatus,
      apiMessage: state.apiMessage,
    }
  },
  redirectUrl: (state) => {
    return state.redirectUrl
  },
  mobileApp: (state) => {
    return state.mobileApp
  },
}

export const mutations = {
  setLoading(state, loading) {
    if (state.lockLoading) return
    state.loading = loading
  },
  lockLoading(state) {
    state.lockLoading = true
  },
  unlockLoading(state) {
    state.lockLoading = false
  },
  setApiError(state, payload) {
    state.apiStatus = payload.apiStatus
    state.apiMessage = payload.apiMessage
  },
  setRedirectUrl(state, url) {
    state.redirectUrl = url
  },
  setMobileApp(state, value) {
    state.mobileApp = value
  },
}
