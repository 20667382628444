export const state = () => ({
  visible: false,
  message: null,
})

export const mutations = {
  visible(state, message) {
    state.visible = true
    state.message = message
  },
  invisible(state) {
    state.visible = false
    state.message = null
  },
}
