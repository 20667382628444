// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/images/photo.png");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@use \"sass:map\";.p-500[data-v-eb24cc5a]{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;background-size:300px;background-position:center 240px;padding:100px 0 576px}@media (min-width:1024px){.p-500[data-v-eb24cc5a]{background-size:347px;background-position:right 234px;padding:227px 0 385px}}.p-500__title[data-v-eb24cc5a]{color:#4d4d4d;font-size:2.2rem;font-weight:400}@media (min-width:1024px){.p-500__title[data-v-eb24cc5a]{font-size:4.4rem}}.p-500__item[data-v-eb24cc5a]{color:#8f8f8f;margin-top:1rem;margin-bottom:2rem;font-size:1.6rem}@media (min-width:1024px){.p-500__item[data-v-eb24cc5a]{width:60%;font-size:1.8rem;margin-bottom:5.5rem}}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
