<template>
  <div class="p-400">
    <h2 class="p-400__title">404 NOT FOUND</h2>
    <p class="p-400__item">
      {{ $t('page-couldnot-found') }}
    </p>
    <router-link to="/">{{ $t('top-page') }}</router-link>
  </div>
</template>

<script>
export default {
  layout: 'errorPage',
}
</script>

<style lang="scss" scoped>
@import '~assets/scss/foundation/base.scss';
.p-400 {
  background: url('~assets/images/photo.png') no-repeat;
  background-size: 300px;
  background-position: center 240px;
  padding: 77px 0 576px;
  @include desktop() {
    background-size: 520px;
    background-position: right 234px;
    padding: 227px 0 365px;
  }
  &__title {
    color: color-text(body1);
    font-size: 2.2rem;
    font-weight: normal;
    @include desktop() {
      font-size: 4.4rem;
    }
  }
  &__item {
    color: color-text(gray);
    margin-top: 1rem;
    margin-bottom: 2rem;
    font-size: 1.6rem;
    @include desktop() {
      font-size: 1.8rem;
      width: 60%;
      margin-bottom: 5.5rem;
    }
  }
}
</style>
