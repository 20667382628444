<template>
  <div>
    <transition name="fade">
      <ribon v-if="visible" />
    </transition>
    <l-header />
    <div class="l-container">
      <nuxt />
    </div>
    <l-footer />
    <transition name="fade">
      <app-loading />
    </transition>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import lFooter from '~/components/layouts/footer.vue'
import lHeader from '~/components/layouts/header.vue'
import Ribon from '~/components/object/navi/Ribon.vue'
import AppLoading from '~/components/object/navi/Loading.vue'

export default {
  components: {
    lFooter,
    lHeader,
    Ribon,
    AppLoading,
  },
  data() {
    return {
      statusCode: null,
    }
  },
  computed: {
    ...mapState('notify', ['visible']),
  },
  created() {
    this.setLoading(true)
  },
  mounted() {
    // this.setLoading(false)
    setTimeout(() => this.setLoading(false), 500)
  },
  methods: {
    ...mapMutations('common', ['setLoading']),
  },
}
</script>

<style lang="scss" scoped>
@import '~assets/scss/foundation/base.scss';

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.l-container {
  padding: 0 15px;
  margin: 0 auto;
  margin-top: calc(#{$headerHeightSP} + 24px);

  @include desktop() {
    padding: 0;
    max-width: 800px;
    margin-top: calc(#{$headerHeightPC} + 40px);
  }
}
</style>
