import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _1eb3e181 = () => interopDefault(import('../pages/400.vue' /* webpackChunkName: "pages/400" */))
const _539a1cc2 = () => interopDefault(import('../pages/500.vue' /* webpackChunkName: "pages/500" */))
const _5118e524 = () => interopDefault(import('../pages/auth/index.vue' /* webpackChunkName: "pages/auth/index" */))
const _569a4a94 = () => interopDefault(import('../pages/change/index.vue' /* webpackChunkName: "pages/change/index" */))
const _9205fc0e = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _d2b257e0 = () => interopDefault(import('../pages/logout/index.vue' /* webpackChunkName: "pages/logout/index" */))
const _6a7f7ee0 = () => interopDefault(import('../pages/maintenance.vue' /* webpackChunkName: "pages/maintenance" */))
const _b89459ae = () => interopDefault(import('../pages/register/index.vue' /* webpackChunkName: "pages/register/index" */))
const _e50c7c6a = () => interopDefault(import('../pages/remind/index.vue' /* webpackChunkName: "pages/remind/index" */))
const _2985d816 = () => interopDefault(import('../pages/usersetting/index.vue' /* webpackChunkName: "pages/usersetting/index" */))
const _2759e974 = () => interopDefault(import('../pages/change/mail/index.vue' /* webpackChunkName: "pages/change/mail/index" */))
const _72195b35 = () => interopDefault(import('../pages/register/complete/index.vue' /* webpackChunkName: "pages/register/complete/index" */))
const _25b92038 = () => interopDefault(import('../pages/register/input/index.vue' /* webpackChunkName: "pages/register/input/index" */))
const _24a862b3 = () => interopDefault(import('../pages/register/mail/index.vue' /* webpackChunkName: "pages/register/mail/index" */))
const _9d4a7a5a = () => interopDefault(import('../pages/remind/complete/index.vue' /* webpackChunkName: "pages/remind/complete/index" */))
const _129a4951 = () => interopDefault(import('../pages/remind/mail/index.vue' /* webpackChunkName: "pages/remind/mail/index" */))
const _15076fd8 = () => interopDefault(import('../pages/auth/line/link/index.vue' /* webpackChunkName: "pages/auth/line/link/index" */))
const _d7fe94b6 = () => interopDefault(import('../pages/auth/line/login/index.vue' /* webpackChunkName: "pages/auth/line/login/index" */))
const _ca4eb18e = () => interopDefault(import('../pages/change/mail/link/index.vue' /* webpackChunkName: "pages/change/mail/link/index" */))
const _0d00d810 = () => interopDefault(import('../pages/change/mail/verify/index.vue' /* webpackChunkName: "pages/change/mail/verify/index" */))
const _389028df = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/400",
    component: _1eb3e181,
    name: "400"
  }, {
    path: "/500",
    component: _539a1cc2,
    name: "500"
  }, {
    path: "/auth",
    component: _5118e524,
    name: "auth"
  }, {
    path: "/change",
    component: _569a4a94,
    name: "change"
  }, {
    path: "/login",
    component: _9205fc0e,
    name: "login"
  }, {
    path: "/logout",
    component: _d2b257e0,
    name: "logout"
  }, {
    path: "/maintenance",
    component: _6a7f7ee0,
    name: "maintenance"
  }, {
    path: "/register",
    component: _b89459ae,
    name: "register"
  }, {
    path: "/remind",
    component: _e50c7c6a,
    name: "remind"
  }, {
    path: "/usersetting",
    component: _2985d816,
    name: "usersetting"
  }, {
    path: "/change/mail",
    component: _2759e974,
    name: "change-mail"
  }, {
    path: "/register/complete",
    component: _72195b35,
    name: "register-complete"
  }, {
    path: "/register/input",
    component: _25b92038,
    name: "register-input"
  }, {
    path: "/register/mail",
    component: _24a862b3,
    name: "register-mail"
  }, {
    path: "/remind/complete",
    component: _9d4a7a5a,
    name: "remind-complete"
  }, {
    path: "/remind/mail",
    component: _129a4951,
    name: "remind-mail"
  }, {
    path: "/auth/line/link",
    component: _15076fd8,
    name: "auth-line-link"
  }, {
    path: "/auth/line/login",
    component: _d7fe94b6,
    name: "auth-line-login"
  }, {
    path: "/change/mail/link",
    component: _ca4eb18e,
    name: "change-mail-link"
  }, {
    path: "/change/mail/verify",
    component: _0d00d810,
    name: "change-mail-verify"
  }, {
    path: "/",
    component: _389028df,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
