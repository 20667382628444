<template>
  <div v-if="error.statusCode == 404">
    <not-found />
  </div>
  <div v-else>
    <server-error />
  </div>
</template>

<script>
import NotFound from '~/pages/400.vue'
import ServerError from '~/pages/500.vue'
export default {
  components: {
    NotFound,
    ServerError,
  },
  layout: 'errorPage',
  props: {
    error: {
      type: Object,
      required: true,
    },
  },
}
</script>
